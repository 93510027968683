import $ from 'jquery';
window.jQuery = $;
window.$ = $;
require("jquery-ui/ui/widgets/datepicker");

import 'js-beautify/js/lib/beautify-html.js';
var html_beautify = require('js-beautify').html;
window.html_beautify = html_beautify;

import toastr from 'toastr';
window.toastr = toastr;

import dragula from 'dragula';
window.dragula = dragula;
